import React from "react"
// Components
import { Header } from "./Header"
import { Main } from "./Main"
import { BeaverBG } from "./BeaverBG"
import { Footer } from "./Footer"
import "./layout.css"
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";

const Layout = ({ children }) => {

  return (
    <>
      <Header />
      <Main>
        {children}
      </Main>
      <BeaverBG />
      <Footer />
    </>
  )
}

export default Layout
