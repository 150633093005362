import React from 'react';
// Style
import { Container } from './Container1200.styles';

const Container1200 = ({ children,className,flex }) => {

  return (
    <Container flex={flex} className={className}>
      {children}
    </Container>
  )
}

export default Container1200;