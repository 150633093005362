import React from 'react'
// Style
import {
  Container,
  Copyright,
  StyledContainer1200,
  Social,
  SocialButton
} from './Footer.styles';

const Footer = () => {
  const year = new Date().getFullYear();

  return(
    <Container>
      <StyledContainer1200 flex="true" className="flex-reverse">
        <Copyright>©{year} The Ambitious Beaver</Copyright>
        <Social>
          <SocialButton href="https://twitter.com/ambitiousbeaver">
            <i className="fab fa-twitter"></i>
          </SocialButton>
          <SocialButton href="https://www.facebook.com/ambitiousbeaver">
            <i className="fab fa-facebook"></i>
          </SocialButton>
          <SocialButton href="https://www.instagram.com/ambitiousbeaver/">
            <i className="fab fa-instagram"></i>
          </SocialButton>
        </Social>
      </StyledContainer1200>
    </Container>
  )
}

export default Footer