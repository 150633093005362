import styled from 'styled-components';
import beaverbg from '../../images/backgrounds/beaver-tree-fell.svg';

export const Container = styled.div`
  width: 100%;
  height: 170px;
  font-size: 2rem;
  text-align: center;
  background-image: url(${beaverbg});
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 900px;
  // Fix the browser not aligning the
  //elements flushly:
  margin-bottom: -1px;

  @media (min-width: 992px) {
    height: 230px;
    background-size: 1200px;
  }

  @media (min-width: 1200px) {
    height: 250px;
    background-position: 50% 100%;
    background-size: 1200px;
  }
`;
