import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;

  ${({ flex }) => flex && `
    display: flex;
  `}
`;