import React from 'react'
// Style
import { Container } from './BeaverBG.styles';

const BeaverBG = () => {

  return(
    <Container />
  )
}

export default BeaverBG