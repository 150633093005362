import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors as c } from '../../styles/colors';

export const Container = styled.header`
  width: 100%;
  display: flex;
  padding: 22px 0 30px;
  border-top: 8px solid ${c.greenDark};

  @media(min-width: 768px) {
    padding: 30px 0;
  }
`;

export const LogoLink = styled(Link)`
  display: inline-block;
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
  width: 80px;
  height: 80px;

  ${({ largeLogo }) => largeLogo && `
  
    @media (min-width: 768px) {
      width: 120px;
      height: 120px;
    }
  `}
`;

export const MenuWrapper = styled.div`
  position: relative;
  font-size: 3rem;
  flex: 1;
  text-align: right;
  height: 45px;
`;
