import React, { useState } from 'react'
// Components
import { Container1200 } from '../Container1200';
import { Menu } from '../Menu';
import { MenuButton } from '../MenuButton';
// Images
import logoOffwhite from '../../images/logos/ab-logo.svg';
// Style
import {
  Container,
  Logo,
  LogoLink,
  MenuWrapper
} from './Header.styles';

const Header = ({ largeLogo=false }) => {
  const [menuOpen,setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return(
    <Container>
      <Container1200 flex="true">
        <LogoLink to="/">
          <Logo src={logoOffwhite} alt="ambitious beaver logo" largeLogo={largeLogo} />
        </LogoLink>
        <MenuWrapper>
          <MenuButton onClick={toggleMenu} open={menuOpen} />
          <Menu open={menuOpen} toggleOpen={toggleMenu} />
        </MenuWrapper>
      </Container1200>
    </Container>
  )
}

export default Header