export const colors = {
  blackText: "#3C3C3C",
  grayLine: "#AAAAAA",
  grayPaper: "#ECECEC",
  grayText: "#636363",
  greenCTA: "#4C8C7B",
  greenDark: "#A8CAC1",
  greenLight: "#BCDDD4",
  purpleText: "#551A8B",
  redError: "#C76C7E"
}