import React from 'react';
// Style
import {
  Container,
  Line
} from './MenuButton.styles';

const MenuButton = ({ onClick,open }) => {

  return(
    <Container onClick={onClick} open={open}>
      <Line move={open} />
      <Line move={open} />
      <Line move={open} />
    </Container>
  )
}

export default MenuButton;