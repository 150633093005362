import React from 'react'
import { graphql,Link,StaticQuery } from 'gatsby';
// Style
import {
  Container
} from './Menu.styles';

const Menu = ({ data,open,toggleOpen }) => {
  const query = graphql`
    query NewestPostQuery {
      allSanityPost(limit: 1, sort: {order: DESC, fields: publishedAt}) {
        edges {
          node {
            slug {
              current
            }
          }
        }
      }
    }
  `;

  return(
    <Container open={open}>
      <Link to="/" onClick={toggleOpen}>Home</Link>
      <StaticQuery query={query} render={data => (
        <Link to={`/post/${data.allSanityPost.edges[0].node.slug.current}`} onClick={toggleOpen}>Newest Post</Link>
      )} />
      <Link to="/collection" onClick={toggleOpen}>The Collection</Link>
      <Link to="/author-bios" onClick={toggleOpen}>Author Bios</Link>
      <Link to="/contact-us" onClick={toggleOpen}>Contact</Link>
    </Container>
  )
}

export default Menu