import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  position: relative;
  z-index: 11;
  width: 42px;
  height: 32px;
  -webkit-tap-highlight-color: transparent;

  ${({ open }) => open && `

    @media (max-width: 767px) {
      position: fixed;
      top: 38px;
      right: 30px;
    }
  `}
`;

export const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: ${c.blackText};
  border-radius: 8px;
  transition: all .1s linear;

  &:nth-child(1) {
    top: 0;
    left: 0;

    ${({ move }) => move && `
      top: 13px;
      transform: rotate(45deg);
    `}
  }

  &:nth-child(2) {
    top: 13px;
    left: 0;

  ${({ move }) => move && `
    opacity: 0;
  `}
  }

  &:nth-child(3) {
    bottom: 0;
    left: 0;

  ${({ move }) => move && `
    bottom: 13px;
    transform: rotate(-45deg);
  `}
  }
`;
