import styled from 'styled-components';
import { Container1200 } from '../../components/Container1200';
import { colors as c } from '../../styles/colors';
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const Container = styled.footer`
  width: 100%;
  display: flex;
  padding: 40px 0;
  background-color: ${c.greenDark};
`;

export const StyledContainer1200 = styled(Container1200)`
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Copyright = styled.p`
  font-size: 1.6rem;
  color: ${c.grayText};
  display: flex;
  align-items: flex-end;
`;

export const Social = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    justify-content: flex-end;
    margin-bottom: 0;
  }
`;

export const SocialButton = styled(OutboundLink)`
  display: flex;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 6px;
  background-color: transparent;
  font-size: 30px;
  color: ${c.grayText};
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  text-decoration: none;

  svg {
    padding: 0;
  }
`;
