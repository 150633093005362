import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  visibility: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  text-align: center;
  background-color: ${c.greenDark};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .2s, visibility 0s;

  ${({ open }) => open && `
    opacity: 1;
    visibility: visible;
  `}

  @media (min-width: 768px) {
    position: absolute;
    top: 115%;
    right: 0;
    left: auto;
    width: auto;
    height: auto;
    padding: 20px;
    box-shadow: 2px 3px 3px rgba(0,0,0,0.4)
  }

  a {
    position: relative;
    display: block;
    font-size: 2.2rem;
    text-transform: uppercase;
    padding: 18px;
    color: ${c.blackText};
    text-decoration: none;
    width: 100%;
    max-width: 300px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 1px;
      background-color: ${c.grayPaper};
    }

    &:last-of-type{

      &::after {
        display: none;
      }
    }
  }
`;
